import React, { useState, useEffect, ReactElement } from 'react'
import { useFormik } from 'formik'
import { FilterCompanySchema } from 'helpers/validationSchemas'
import { useTranslation } from 'react-i18next'
import { DrawerContent, Title } from 'components/DrawerLayout/styled-components'
import {
  FormContainer,
  FormGroupWrapper,
  FormFields,
  FormField,
  FormFooter,
  FormButtons,
} from 'components/common/Form/Form'
import Loader from 'components/common/Loader'
import TextInput from 'components/common/TextInput/TextInput'
import { Button } from 'components/common/Button'
import { SelectField } from 'components/common/SelectField'
import { platformServiceOptions } from 'pages/companies/staticData'
import DateRangePicker from 'components/common/DateRangePicker'
import { UpperCaseFilterableFieldType } from 'enums/filterEnum'
import { IFilterCompanies, IFormValues } from './filterCompanies.interface'
import { useFilterContext } from 'context/FilterContext'
import { useFilterRoleContext } from 'context/FilterRoleContext'
import { IFilterQueryType } from 'components/common/FilterInterface/filter.interface'
import CustomDrawerHeader from 'components/common/CustomDrawerHeader'

const FilterCompanies = ({
  onClose,
  filterLoading,
  filter,
  setFilterOptions,
  setCurrentPage,
  filterOptions,
}: IFilterCompanies): ReactElement => {
  const { t } = useTranslation()
  const [error] = useState(false)
  const { createDate, setCreateDate } = useFilterContext()
  const { filterRole, setFilterRole } = useFilterRoleContext()

  setFilterRole('COMPANIES')

  const { handleSubmit, handleChange, values, setFieldValue } = useFormik({
    initialValues: filterOptions as IFilterQueryType,
    validationSchema: FilterCompanySchema,
    onSubmit(values) {
      const formValues: IFormValues = {}

      if (values.name) {
        formValues.name = {
          type: UpperCaseFilterableFieldType.MATCH,
          value: values.name as string,
        }
      }

      if (values.platformService?.value !== 'all') {
        formValues.platformService = {
          type: UpperCaseFilterableFieldType.EXACT,
          value: values.platformService?.value as string,
        }
      }

      if (values.status?.value !== 'all') {
        formValues.status = {
          type: UpperCaseFilterableFieldType.EXACT,
          value: values.status?.value as string,
        }
      }

      //if user wants to clear the date, we should send undefined
      const safeCreateDate = createDate || [undefined, undefined]

      safeCreateDate[0] = safeCreateDate[0] && new Date(safeCreateDate[0])
      safeCreateDate[1] = safeCreateDate[1] && new Date(safeCreateDate[1])

      const isSameDate = safeCreateDate[0] === safeCreateDate[1] && safeCreateDate[0] !== undefined

      if (!safeCreateDate[1] && safeCreateDate[0] !== undefined) safeCreateDate[1] = new Date()

      const formattedData = isSameDate
        ? safeCreateDate[0]
        : `${safeCreateDate[0] ? safeCreateDate[0].toISOString() : ''}${'-'}${
            safeCreateDate[1] ? safeCreateDate[1].toISOString() : ''
          }`

      if (safeCreateDate[0] !== undefined || safeCreateDate[1] !== undefined) {
        formValues.createDate = {
          type: isSameDate
            ? UpperCaseFilterableFieldType.EXACT
            : UpperCaseFilterableFieldType.DATE_RANGE,
          value: formattedData as string,
        }
      }

      setCurrentPage(1)
      setFilterOptions(values as IFilterQueryType)
      filter(formValues)
    },
  })

  //clear createDate value when we switch tabs
  useEffect(() => {
    if (createDate && filterRole != 'COMPANIES') {
      setCreateDate([undefined, undefined])
    }
  }, [filterRole])

  return (
    <DrawerContent>
      {filterLoading && <Loader />}
      <CustomDrawerHeader
        title={`${t('general.advanced_filters')} - ${t('companies_layout.filter_companies')}`}
        onClose={(): void => onClose('reset')}
      />
      <FormContainer onSubmit={handleSubmit}>
        <FormGroupWrapper>
          <FormFields>
            <FormField>
              <TextInput
                title={t('form_fields.name')}
                placeholder={t('form_fields.name')}
                name='name'
                size='small'
                type='text'
                value={values.name as string}
                onChange={handleChange}
              />
            </FormField>

            <FormField style={{ position: 'relative' }}>
              <SelectField
                className='select-platform'
                label={t('companies_layout.platform_service')}
                options={platformServiceOptions}
                value={values.platformService}
                onChange={(e: string): Promise<void> =>
                  setFieldValue('platformService', e) as Promise<void>
                }
              />
            </FormField>
            <FormField style={{ position: 'relative' }}>
              <Title>{t('general.create_date')}</Title>
              <DateRangePicker values={createDate} setCreateDate={setCreateDate} />
            </FormField>
          </FormFields>
        </FormGroupWrapper>

        <FormFooter>
          <FormButtons>
            <Button
              text={t('actions.filter')}
              type='small'
              color='secondary'
              btnType='submit'
              background='#06C68F'
              isDisabled={!!error}
            />
            <Button
              text={t('actions.cancel')}
              type='small'
              onClick={(): void => onClose('reset')}
              background='#E0E1E2'
              textColor='#414141'
            />
          </FormButtons>
        </FormFooter>
      </FormContainer>
    </DrawerContent>
  )
}

export default FilterCompanies
