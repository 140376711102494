import { IFilterQuery } from 'components/common/FilterInterface/filter.interface'
import i18n from 'i18n/index'

interface IFilterOptions {
  name: string
  platformService: {
    value: string | boolean
    label: string
  }
  status: {
    value: string
    label: string
  }
  createDate: [Date | null, Date | null]
}

export let filterOptions: IFilterOptions = {
  name: '',
  platformService: { value: 'all', label: 'All' },
  status: { value: 'all', label: 'All' },
  createDate: [null, null],
}

export let platformServiceOptions: IFilterQuery[] = []

function translate(): void {
  platformServiceOptions = [
    { value: 'all', label: i18n.t('general.all') },
    { value: false, label: i18n.t('general.disabled') },
    { value: true, label: i18n.t('general.enabled') },
  ]

  filterOptions = {
    name: '',
    platformService: { value: 'all', label: i18n.t('general.all') },
    status: { value: 'all', label: i18n.t('general.all') },
    createDate: [null, null],
  }
}

i18n.on('languageChanged init', () => {
  translate()
})
